import { combineReducers } from "redux";
import { History } from "history";

import authReducer from "./slices/authSlice";
import monitorReducer from "./slices/monitorSlice";
import alertReducer from "./slices/alertSlice";
import mlReducer from "./slices/mlSlice";
import pageReducer from "./slices/pageSlice";

export default function createRootReducer(history: History) {
  return combineReducers({
    auth: authReducer,
    monitor: monitorReducer,
    alert: alertReducer,
    ml: mlReducer,
    page: pageReducer,
  });
}
