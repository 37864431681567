import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkSubscription } from "../slices/authSlice";
import { useAppDispatch } from "../store";

export default function SubscriptionCreatedView() {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // add some timeout to make sure that vendor will have updated data
    // 3 seconds should be fine
    // in the worst scenario, the page should be reloaded
    setTimeout(() => dispatch(checkSubscription()), 3000);
  }, [dispatch]);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1> Thank you! 🎉</h1>

            <p>
              You are using <b>Pro</b> plan. You can create up to 20 monitors.
              <br />
              <button
                className="btn btn-success my-2"
                onClick={() => navigate("/app/")}
              >
                Create a new monitor
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
