import { useState } from "react";
import { useAppDispatch } from "../store";

import {
  getEmailRegisterError,
  getPassword1RegisterError,
  getUsernameRegisterError,
  register,
} from "../slices/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function RegisterView() {
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const dispatch = useAppDispatch();

  const usernameRegisterError = useSelector(getUsernameRegisterError);
  const emailRegisterError = useSelector(getEmailRegisterError);
  const password1RegisterError = useSelector(getPassword1RegisterError);

  return (
    <div>
      <div
        className="container"
        style={{ paddingTop: "70px", paddingBottom: "70px" }}
      >
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <h1 className="h3 mb-3 fw-normal">Register</h1>
            <div className="my-2 has-validation">
              <label>User name</label>
              <input
                type="text"
                className={`form-control form-control-lg ${
                  usernameRegisterError ? "is-invalid" : ""
                }`}
                placeholder="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className="invalid-feedback">{usernameRegisterError}</div>
            </div>
            <div className="my-2 has-validation">
              <label>Email address</label>
              <input
                type="email"
                className={`form-control form-control-lg ${
                  emailRegisterError ? "is-invalid" : ""
                }`}
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="invalid-feedback">{emailRegisterError}</div>
            </div>
            <div className="my-2 has-validation">
              <label>Password</label>
              <input
                type="password"
                className={`form-control form-control-lg ${
                  password1RegisterError ? "is-invalid" : ""
                }`}
                placeholder="Password"
                value={password1}
                onChange={(e) => setPassword1(e.target.value)}
              />
              <div className="invalid-feedback">{password1RegisterError}</div>
            </div>

            <div className="my-2">
              <label>Repeat password</label>
              <input
                type="password"
                className="form-control form-control-lg"
                placeholder="Repeat password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
              />
            </div>

            <button
              className="w-100 btn btn-lg btn-primary my-2"
              disabled={
                !(
                  username !== "" &&
                  email !== "" &&
                  password1 !== "" &&
                  password1 === password2
                )
              }
              onClick={(e) => {
                dispatch(
                  register(username, email, password1, password2, navigate)
                );
              }}
            >
              Register
            </button>

            <p className="my-2">
              By registering at our Site, you confirm that you have read the{" "}
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
              ,{" "}
              <a href="/terms-of-service" target="_blank">
                Terms of Service
              </a>
              , and agree to their terms.
            </p>

            <p className="my-2">
              <a href="/login">Login</a>
              <a className="m-3" href="/reset-password">
                Forgot password?
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
