import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../components/AlertComponent";
import MonitorDropdown, {
  getMonitorIdSelection,
} from "../components/MonitorDropdown";
import { fetchAlerts, getAlerts } from "../slices/alertSlice";
import {
  fetchMonitors,
  getMonitors,
  getSelectedMonitor,
  getSelectedMonitorId,
  setSelectedMonitorId,
} from "../slices/monitorSlice";
import { useAppDispatch } from "../store";

export default function AlertsView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const monitors = useSelector(getMonitors);
  const alerts = useSelector(getAlerts);
  const selectedMonitorId = useSelector(getSelectedMonitorId);
  const selectedMonitor = useSelector(getSelectedMonitor);

  useEffect(() => {
    dispatch(fetchMonitors());
  }, [dispatch]);

  let selectedId = getMonitorIdSelection(selectedMonitorId, monitors);

  useEffect(() => {
    if (selectedId) {
      dispatch(fetchAlerts(selectedId));

      if (selectedMonitor && selectedId !== selectedMonitor.id) {
        dispatch(setSelectedMonitorId(selectedId));
      }
    }
  }, [dispatch, selectedId, selectedMonitor]);

  let alertComponents = alerts.map((alert) => {
    return (
      <AlertComponent
        alert={alert}
        monitorId={selectedId}
        key={`alert-${alert.id}`}
      />
    );
  });

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2 style={{ float: "left" }}>
              <i className="bi bi-bell"></i> Alerts
            </h2>

            <button
              className="btn btn-success "
              style={{ float: "right" }}
              onClick={(e) => {
                navigate(`/app/add-alert/${selectedId}`);
              }}
              title="Add new alert"
            >
              <i className="bi bi-plus"></i> Add alert
            </button>
          </div>
        </div>

        <MonitorDropdown
          selectedId={selectedId}
          monitors={monitors}
        />

        <div className="row">
          <div className="col-md-10 offset-md-1">
            {alerts.length === 0 && <p>No alerts available</p>}
            {alertComponents}
          </div>
        </div>
      </div>
    </div>
  );
}
