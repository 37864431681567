import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getToken } from "../slices/authSlice";

export default function NavBar() {
  let token = useSelector(getToken);

  return (
    <div className="container">
      <header className="d-flex flex-wrap justify-content-center py-3 mb-4 px-5 mx-5">
        <Link
          to="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
        >
          <img
            alt="monitor uptime"
            src={process.env.PUBLIC_URL + "/logo-small.png"}
            style={{ height: "40px", padding: "3px" }}
          />
          <span className="fs-4">Monitor Uptime</span>
        </Link>

        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link to="/pricing" className="nav-link">
              Pricing
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-link">
              About
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/contact" className="nav-link">
              Contact
            </Link>
          </li>
          {!token && (
            <li className="nav-item">
              <b className="nav-link px-1" style={{ color: "gray" }}>
                |
              </b>
            </li>
          )}
          {!token && (
            <li className="nav-item">
              <Link to="/register" className="nav-link">
                Sign Up
              </Link>
            </li>
          )}
          {!token && (
            <li className="nav-item">
              <Link to="/login" className="nav-link active">
                Login
              </Link>
            </li>
          )}
          {token && (
            <li className="nav-item">
              <Link to="/app" className="nav-link active">
                App
              </Link>
            </li>
          )}
        </ul>
      </header>
    </div>
  );
}
