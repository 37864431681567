import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchDailyStats,
  fetchLatestRequests,
  fetchMonitors,
  getDailyStats,
  getLatestRequests,
  getMonitors,
  getSelectedMonitor,
  getSelectedMonitorId,
  setSelectedMonitorId,
} from "../slices/monitorSlice";
import { useAppDispatch } from "../store";
import MonitorNumbers from "../components/MonitorNumbers";
import MonitorCharts from "../components/MonitorCharts";
import MonitorButtons from "../components/MonitorButtons";
import MonitorDropdown, {
  getMonitorIdSelection,
} from "../components/MonitorDropdown";

export default function AppView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const monitors = useSelector(getMonitors);
  const selectedMonitorId = useSelector(getSelectedMonitorId);
  const selectedMonitor = useSelector(getSelectedMonitor);
  const latestRequests = useSelector(getLatestRequests);
  const dailyStats = useSelector(getDailyStats);

  useEffect(() => {
    dispatch(fetchMonitors());
  }, [dispatch]);

  let selectedId = getMonitorIdSelection(selectedMonitorId, monitors);

  useEffect(() => {
    if (selectedId) {
      dispatch(fetchLatestRequests(selectedId));
      dispatch(fetchDailyStats(selectedId));
      if (selectedMonitor && selectedId !== selectedMonitor.id) {
        dispatch(setSelectedMonitorId(selectedId));
      }
    }
  }, [dispatch, selectedId, selectedMonitor]);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2 style={{ float: "left" }}>
              <i className="bi bi-bar-chart"></i> Monitors
            </h2>

            {monitors.length > 0 && (
              <MonitorButtons
                selectedId={selectedId}
                selectedMonitor={selectedMonitor}
              />
            )}

            <button
              className="btn btn-success "
              style={{ float: "right" }}
              onClick={(e) => {
                navigate("/app/add-monitor");
              }}
              title="Add new monitor"
            >
              <i className="bi bi-plus"></i> Add monitor
            </button>
          </div>

          {monitors.length === 0 && (
            <div className="col-md-10 offset-md-1">
              <hr style={{ marginTop: "0px" }} />
              <p>Please add your first monitor.</p>
            </div>
          )}
        </div>
        {monitors.length > 0 && (
          <div>
            <MonitorDropdown
              selectedId={selectedId}
              monitors={monitors}
            />

            <div className="row">
              <div className="col-md-10 offset-md-1">
                {dailyStats && dailyStats.x_data && (
                  <MonitorNumbers
                    avgPercentCorrect={dailyStats.avg_percent_correct}
                    totalFailurePeriod={dailyStats.total_failure_period}
                    avgResponseTime={dailyStats.avg_response_time}
                    totalRequestsCount={dailyStats.total_requests_count}
                    totalDays={dailyStats.x_data.length}
                  />
                )}

                <MonitorCharts
                  dailyStats={dailyStats}
                  latestRequests={latestRequests}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
