import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addAlert, AlertType, updateAlert } from "../slices/alertSlice";
import { useAppDispatch } from "../store";

interface AlertProp {
  state: {
    alert: AlertType;
  };
}

export default function AddAlertView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { state } = useLocation() as unknown as AlertProp;
  let { monitorId } = useParams();

  let updateView = false;
  let alert = {} as AlertType;

  let defaultNotification = "note";
  let defaultMsg = "";
  let alertId: undefined | number = undefined;
  if (state) {
    updateView = true;
    alert = state.alert;
    defaultNotification = alert.notification;
    defaultMsg = alert.msg;
    alertId = alert.id;
  }

  const [notification, setNotification] = useState(defaultNotification);
  const [msg, setMsg] = useState(defaultMsg);

  const okFunction = updateView ? updateAlert : addAlert;

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            {!updateView && <h2 style={{ float: "left" }}>Add Alert</h2>}
            {updateView && <h2 style={{ float: "left" }}>Update Alert</h2>}
          </div>

          <div className="col-md-10 offset-md-1">
            <form
              className="form-signin"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div className="my-2">
                <label>Alert type</label>

                <select
                  className="form-select"
                  value={notification}
                  onChange={(e) => setNotification(e.target.value)}
                >
                  <option value="alert">Alert</option>
                  <option value="warning">Warning</option>
                  <option value="note">Note</option>
                </select>
              </div>

              <div className="my-2">
                <label>Message</label>
                <textarea
                  
                  className="form-control form-control"
                  placeholder="Your message"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                />
              </div>

              <button
                className="w-25 btn btn-success my-2"
                onClick={(e) => {
                  dispatch(
                    okFunction(monitorId, alertId, notification, msg, navigate)
                  );
                }}
              >
                OK
              </button>
              <button
                className="w-25 btn btn-secondary my-2 mx-2"
                onClick={(e) => {
                  navigate("/app");
                }}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
