import { Link, useNavigate } from "react-router-dom";
import { logout } from "../slices/authSlice";
import { useAppDispatch } from "../store";

export default function AppNavBar() {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className="container">
      <header className="d-flex flex-wrap justify-content-center py-3 mb-4 px-5 mx-5">
        <Link
          to="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
        >
          <img
            alt="monitor uptime"
            src={process.env.PUBLIC_URL + "/logo-small.png"}
            style={{ height: "40px", padding: "3px" }}
          />
          <span className="fs-4">Monitor Uptime</span>
        </Link>

        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link to="/app" className="nav-link">
              <i className="bi bi-bar-chart"></i> Monitors
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/app/ml" className="nav-link">
              <i className="bi bi-cpu"></i> Anomaly Detection
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/app/alerts" className="nav-link">
              <i className="bi bi-bell"></i> Alerts
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/app/status-pages" className="nav-link">
              <i className="bi bi-display"></i> Status Pages
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/app/settings" className="nav-link">
              <i className="bi bi-sliders"></i> Settings
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/"
              className="nav-link active"
              onClick={() => dispatch(logout(navigate))}
            >
              <i className="bi bi-box-arrow-right"></i> Logout
            </Link>
          </li>
        </ul>
      </header>
    </div>
  );
}
