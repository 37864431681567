import React, { ReactNode } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import HomeView from "./views/HomeView";
import RegisterView from "./views/RegisterView";
import LoginView from "./views/LoginView";
import PricingView from "./views/PricingView";
import PrivacyPolicyView from "./views/PrivacyPolicyView";
import TermsOfServiceView from "./views/TermsOfServiceView";
import ContactView from "./views/ContactView";
import AboutView from "./views/AboutView";
import ResetPasswordView from "./views/ResetPasswordView";
import VerifyEmailView from "./views/VerifyEmailView";
import VerifyEmailSentView from "./views/VerifyEmailSentView";
import AppView from "./views/AppView";
import RequireAuth from "./components/RequireAuth";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import AppNavBar from "./components/AppNavBar";
import StatusPagesView from "./views/StatusPagesView";
import SettingsView from "./views/SettingsView";
import SubscriptionCreatedView from "./views/SubscriptionCreatedView";
import AlertsView from "./views/AlertsView";

import BlockUi from "react-block-ui";
import { getBlockUI } from "./slices/authSlice";
import { useSelector } from "react-redux";
import ResetPasswordEmailSentView from "./views/ResetPasswordEmailSentView";
import ResetPasswordConfirmView from "./views/ResetPasswordConfirmView";
import AddMonitorView from "./views/AddMonitor";
import AddAlertView from "./views/AddAlert";
import MLView from "./views/MLView";
import AddStatusPage from "./views/AddStatusPage";
import StatusPagePublicView from "./views/StatusPagePublicView";

type Props = {
  children: ReactNode;
};

function App(props: Props) {
  const { children } = props;
  const blocking = useSelector(getBlockUI);
  return <BlockUi blocking={blocking}>{children}</BlockUi>;
}

function WebLayout() {
  return (
    <div>
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
}

function AppLayout() {
  return (
    <RequireAuth>
      <>
        <AppNavBar />
        <Outlet />
        <Footer />
      </>
    </RequireAuth>
  );
}
export default function AppRoutes() {
  return (
    <Router>
      <App>
        <Routes>
          <Route path="/" element={<WebLayout />}>
            <Route path="/" element={<HomeView />} />
            <Route path="/register" element={<RegisterView />} />
            <Route path="/login" element={<LoginView />} />
            <Route path="/pricing" element={<PricingView />} />
            <Route path="/contact" element={<ContactView />} />
            <Route path="/about" element={<AboutView />} />
            <Route path="/reset-password" element={<ResetPasswordView />} />
            <Route
              path="/reset-password-email-sent"
              element={<ResetPasswordEmailSentView />}
            />
            <Route
              path="/verify-email-sent"
              element={<VerifyEmailSentView />}
            />
            <Route
              path="/reset-password/:uid/:token"
              element={<ResetPasswordConfirmView />}
            />
            <Route path="/verify-email/:key" element={<VerifyEmailView />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyView />} />
            <Route path="/terms-of-service" element={<TermsOfServiceView />} />
            <Route
              path="*"
              element={
                <h2 style={{ textAlign: "center" }}>
                  Ups. There's nothing here: 404!
                </h2>
              }
            />
          </Route>
          
          <Route path="/public/:slug" element={<StatusPagePublicView />} />

          <Route path="/app" element={<AppLayout />}>
            <Route path="/app" element={<AppView />} />
            <Route path="/app/alerts" element={<AlertsView />} />
            <Route path="/app/ml" element={<MLView />} />
            <Route path="/app/status-pages" element={<StatusPagesView />} />
            <Route path="/app/add-status-page" element={<AddStatusPage />} />
            
            <Route path="/app/settings" element={<SettingsView />} />
            <Route
              path="/app/subscription-created"
              element={<SubscriptionCreatedView />}
            />
            <Route path="/app/add-monitor" element={<AddMonitorView />} />
            <Route path="/app/add-alert/:monitorId" element={<AddAlertView />} />
            
          </Route>
        </Routes>
      </App>
    </Router>
  );
}
