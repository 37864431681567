import { MonitorDataType } from "../slices/pageSlice";
import StatusBars from "./StatusBars";

type Props = {
  monitor: MonitorDataType;
};

export default function PublicMonitorComponent({ monitor }: Props) {
  return (
    <div>
      <big>{monitor.title} ({monitor.endpoint})</big>
      <StatusBars monitor={monitor} />
    </div>
  );
}
