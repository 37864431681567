import { useState } from "react";
import { cancelSubscription, UserType } from "../slices/authSlice";
import { useAppDispatch } from "../store";

type Props = {
  user: UserType;
};

declare global {
  interface Window {
    Paddle: any;
  }
}

export default function Subscription({ user }: Props) {
  const dispatch = useAppDispatch();
  const Paddle = window.Paddle;
  const [email, setEmail] = useState("");
  
  Paddle.Setup({
    vendor: 124786, // Paddle Vendor ID
    eventCallback: function (data: any) {
      if (data.event === "Checkout.Complete") {
        // go to subscription created thank you website
        // and run subscription check API
        window.location.replace("/app/subscription-created");
      } else if (data.event === "Checkout.Close") {
        // reload the website
        window.location.reload();
      }
    },
  });

  return (
    <div>
      <h3>Subscription</h3>
      {user.profile.plan === "unknown" && <p>Loading ...</p>}
      {user.profile.plan !== "unknown" && (
        <p>
          You are using <b>{user.profile.plan}</b> plan.
        </p>
      )}
      {user.profile.plan === "free" && (
        <div>
          <p>
            You can monitor up to 20 servers with Pro plan. It costs 19$ per
            month.
          </p>
          <button
            className="btn btn-success"
            onClick={(e) => {
              Paddle.Checkout.open({
                product: 784793, // Plan ID in Paddle
                email: user.email,
              });
            }}
          >
            Upgrade to Pro
          </button>
        </div>
      )}
      {user.profile.plan === "pro" && (
        <div>
          <p>
            You can cancel your monthly subscription by clicking the button
            below. Please write your email <b>{user.email}</b> in the text input
            below to confirm subscription cancelation.
          </p>
          <b>
            Your all monitors will be removed.
          </b>
          <div className="my-2">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Write your email to confirm subscription cancelation"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button
            className="btn btn-danger"
            onClick={(e) => {
              dispatch(cancelSubscription());
            }}
            disabled={email !== user.email}
          >
            Cancel subscription
          </button>
        </div>
      )}
    </div>
  );
}
