
export default function ContactView() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1>Contact</h1>
            <p>Please contact us by email contact@monitor-uptime.com</p>
            <p>
              Our GitHub website:{" "}
              <a
                href="https://github.com/saasitive"
                target="_blank"
                rel="noreferrer"
              >
                github.com/saasitive
              </a>
            </p>

            <h3>Tutorial details</h3>
            <p>
              Please check our website{" "}
              <a href="https://saasitive.com" target="_blank" rel="noreferrer">
                {" "}
                SaaSitive
              </a>{" "}
              for more information about{" "}
              <a
                href="https://saasitive.com/react-django-tutorial/"
                target="_blank"
                rel="noreferrer"
              >
                React and Django tutorial{" "}
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
