import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState, TypedDispatch } from "../store";

export type MLType = {
  info: string;
  samples: number;
  warnings_count: number;
  created_at: Date;
};

const initialState = {
  ml: {} as MLType,
};

const mlSlice = createSlice({
  name: "ml",
  initialState,
  reducers: {
    setML(state, action: PayloadAction<MLType>) {
      state.ml = action.payload;
    },
  },
});

export default mlSlice.reducer;

export const {
  setML,
} = mlSlice.actions;

export const getML = (state: RootState) => state.ml.ml;

export const fetchML = (monitorId: string | number | undefined) => async (dispatch: TypedDispatch) => {
  try {
    const url = `/api/ml/${monitorId}`;
    const response = await axios.get(url);
    dispatch(setML(response.data));
  } catch (error) {
  }
};
