import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUserInfo, getUserInfo } from "../slices/authSlice";
import { fetchMonitors, getMonitors } from "../slices/monitorSlice";
import {
  addPage,
  fetchPages,
  getPages,
  StatusPageType,
  updatePage,
} from "../slices/pageSlice";
import { useAppDispatch } from "../store";

interface StatusPageProp {
  state: {
    page: StatusPageType;
  };
}

export default function AddStatusPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const allMonitors = useSelector(getMonitors);
  const user = useSelector(getUserInfo);
  const pages = useSelector(getPages);

  useEffect(() => {
    dispatch(fetchUserInfo());
    dispatch(fetchMonitors());
    dispatch(fetchPages());
  }, [dispatch]);

  const { state } = useLocation() as unknown as StatusPageProp;

  let updateView = false;
  let page = {} as StatusPageType;

  let defaultSlug = "";
  let defaultTitle = "";
  let defaultDescription = "";
  let defaultMonitors = {} as Record<number, boolean>;
  let pageId: undefined | number = undefined;
  if (state) {
    updateView = true;
    page = state.page;
    defaultSlug = page.slug;
    defaultTitle = page.title;
    defaultDescription = page.description;
    allMonitors.forEach((monitor) => {
      defaultMonitors[monitor.id] = page.monitors.includes(monitor.id);
    });
    pageId = page.id;
  }

  const [slug, setSlug] = useState(defaultSlug);
  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState(defaultDescription);
  const [monitors, setMonitors] = useState(defaultMonitors);

  const okFunction = updateView ? updatePage : addPage;

  let switches = allMonitors.map((monitor) => {
    return (
      <div className="form-check form-switch" key={`switch-${monitor.id}`}>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          checked={monitors[monitor.id]}
          onChange={(e) => {
            let monitorIds = monitors;
            monitorIds[monitor.id] = e.target.value === "on";
            setMonitors(monitorIds);
          }}
        />
        <label className="form-check-label">{monitor.title}</label>
      </div>
    );
  });

  let showForm = true;
  let upgrade = false;
  let proLimit = false;
  if (user.profile.plan === "free" && pages.length >= 1) {
    showForm = false;
    upgrade = true;
  }
  if (user.profile.plan === "pro" && pages.length >= 20) {
    showForm = false;
    proLimit = true;
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            {!updateView && <h2 style={{ float: "left" }}>Add Status Page</h2>}
            {updateView && (
              <h2 style={{ float: "left" }}>Update Status Page</h2>
            )}
          </div>

          {!updateView && upgrade && (
            <div className="col-md-10 offset-md-1">
              <p>
                You reached <b>free</b> plan limit. Please upgrade to <b>Pro</b>{" "}
                plan to add more status pages.
              </p>
              <button
                className="btn btn-success"
                onClick={(e) => navigate("/app/settings")}
              >
                Upgrade to Pro
              </button>
              <button
                className="btn btn-secondary my-2 mx-2"
                onClick={(e) => {
                  navigate("/app");
                }}
              >
                Cancel
              </button>
            </div>
          )}
          {!updateView && proLimit && (
            <div className="col-md-10 offset-md-1">
              <p>
                You reached <b>Pro</b> plan limit. Please contact us at
                contact@saasitive.com for help.
              </p>
            </div>
          )}

          {(showForm || updateView) && (
            <div className="col-md-10 offset-md-1">
              <form
                className="form-signin"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <div className="my-2">
                  <label>Slug</label>
                  <input
                    type="text"
                    className="form-control form-control"
                    placeholder="my-monitor"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                  />
                </div>

                <div className="my-2">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control form-control"
                    placeholder="My status page"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                <div className="my-2">
                  <label>Description</label>
                  <textarea
                    className="form-control form-control"
                    placeholder="Status page for my server"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div className="my-2">
                  <label>Monitors</label>
                  {switches}
                </div>

                <button
                  className="w-25 btn btn-success my-2"
                  disabled={slug === "" || title === ""}
                  onClick={(e) => {
                    let monitorIds = [] as number[];

                    allMonitors.forEach((m) => {
                      if (monitors[m.id]) {
                        monitorIds.push(m.id);
                      }
                    });

                    dispatch(
                      okFunction(
                        pageId,
                        slug,
                        title,
                        description,
                        monitorIds,
                        navigate
                      )
                    );
                  }}
                >
                  OK
                </button>
                <button
                  className="w-25 btn btn-secondary my-2 mx-2"
                  onClick={(e) => {
                    navigate("/app/status-pages");
                  }}
                >
                  Cancel
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
