
export default function VerifyEmailSentView() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1>Verify Email Sent</h1>

            <p>
              Please check your email. We send you verification link. Please
              double-check your inbox. The verification email might be in the
              spam folder.
            </p>

            <p>
              In case of any problems, please contact us by email
              contact@monitor-uptime.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
