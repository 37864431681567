
export default function PrivacyPolicyView() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1>Privacy Policy</h1>

            <p>
              Welcome to <b>monitor-uptime.com</b>, which is a property of{" "}
              <b>MLJAR sp. z o. o.</b>
            </p>
            <p>
              <b>MLJAR sp. z o. o.</b> ("us", "we", or "our") operates{" "}
              <b>monitor-uptime.com</b> (hereinafter referred to as "Service",
              “Monitor Uptime”).
            </p>
            <p>
              Our Privacy Policy governs your visit to monitor-uptime.com and
              explains how we collect, safeguard, and disclose information that
              results from your use of our Service. The following statements
              describe which types of data we collect when you use our services,
              what happens with this data, and how you can object to this data
              processing, where applicable.
            </p>
            <p>
              We use your data to provide and improve our Service. By using
              monitor-uptime.com, you agree to the collection and use of
              information under this policy. The terms used in this Privacy
              Policy have the same meanings as in our{" "}
              <a href="/terms-of-service">Terms of Service</a>.
            </p>
            <p>
              Our Terms of Service (<b>"Terms"</b>) govern the use of our
              Service and, together with the Privacy Policy, constitute your
              agreement with us (<b>"Agreement"</b>).
            </p>

            <p>
              For the purposes of the EU General Data Protection Regulation
              (GDPR) 2016/679, the responsible entity is:
            </p>

            <p>
              MLJAR Sp. z o.o., ul Gen. Władysława Sikorskiego 22A, 18-100 Lapy,
              Poland, VAT: 9662143513
            </p>

            <h2>1. Definitions</h2>
            <p>
              <b>Service</b> - means the monitor-uptime.com website operated by
              MLJAR sp. z o. o.
              <br />
              <b>Personal data</b> - means data about a living individual who
              can be identified from those data (or from those and other
              information either in our possession or likely to come into our
              possession).
              <br />
              <b>Usage data</b> - is data collected automatically either
              generated by the use of Service or from the Service infrastructure
              itself (for example, the duration of a page visit).
              <br />
              <b>Cookies</b> - are small files stored on your device (computer
              or mobile device).
              <br />
              <b>Data Controller</b> - means a natural or legal person who
              (either alone or jointly or in common with other persons)
              determines the purposes for which and how any personal data are or
              are going to be, processed. For the purpose of this Privacy
              Policy, we are a Data Controller of your data.
              <br />
              <b>Data Processors (or Service Providers)</b> - are any natural or
              legal person who processes the data on behalf of the Data
              Controller. We may use the services of various Service Providers
              in order to process your data more effectively.
              <br />
              <b>Data subject</b> is any living individual who is the subject of
              Personal Data.
              <br />
              <b>The User</b> is the individual using our Service. The User
              corresponds to the Data Subject, who is the subject of Personal
              Data.
            </p>

            <h2>2. Information Collection and Use</h2>
            <p>
              We collect several different types of information for various
              purposes to provide and improve our Service to you. Our Service is
              not intended for use by children under the age of 18. We do not
              knowingly collect personally identifiable information from
              children under 18. If you become aware that a child has provided
              us with Personal Data, please let us know. If we become aware that
              we have collected Personal Data from children without verifying
              parental consent, we remove that information.
            </p>

            <h3>Personal Data</h3>
            <p>
              While using our Service, we may ask you to provide us with
              particular, personally identifiable information that can be used
              to contact or identify you (<b>"Personal Data"</b>). Personally,
              identifiable information may include, but is not limited to:
              <ul>
                <li>E-mail address, user name</li>
                <li>First name and last name</li>
                <li>
                  Address, Country, State, Province, ZIP/Postal code, City
                </li>
              </ul>
            </p>

            <p>
              If you give your consent, we may use your Personal Data to contact
              you with newsletters, marketing or promotional materials, and
              other information that may be of interest to you. We also have fan
              pages on social media. You may opt-out of receiving any, or all,
              of these communications from us by following the unsubscribe link.
            </p>

            <h3>Usage Data</h3>
            <p>
              We may also collect information that your browser sends whenever
              you visit our Service or access the Service by or through any
              device (<b>"Usage Data"</b>).
              <br />
              This Usage Data may include information such as your computer's
              Internet Protocol address (e.g., IP address), browser type,
              browser version, the pages of our Service that you visit, the time
              and date of your visit, the time spent on those pages, unique
              device identifiers and other diagnostic data.
              <br />
              When you access Service with a device, this Usage Data may include
              information such as the type of device you use, your unique device
              ID, the IP address of your device, your device operating system,
              the type of Internet browser you use, unique device identifiers
              and other diagnostic data.
            </p>

            <h3>Tracking Cookies Data</h3>
            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service, and we hold certain information.
              <br />
              Cookies are files with a small amount of data, which may include
              an unique anonymous identifier. Cookies are sent to your browser
              from a website and stored on your device. Other tracking
              technologies are also used, such as tags and scripts, to collect,
              track information, improve, and analyze Monitor Uptime.
              <br />
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>

            <h2>3. Use of Data</h2>
            <p>
              MLJAR sp. z o. o. uses the collected data for various purposes,
              especially to:
              <ul>
                <li>provide and maintain our Service;</li>
                <li>notify you about changes to our Service;</li>
                <li>
                  allow you to participate in interactive features of our
                  Service when you choose to do so;
                </li>
                <li>provide customer support;</li>
                <li>
                  gather analysis or valuable information so that we can improve
                  our Service;
                </li>
                <li>monitor the usage of our Service;</li>
                <li>detect, prevent and address technical issues;</li>
                <li>fulfill any other purpose for which you provide it;</li>
                <li>
                  carry out our obligations and enforce our rights arising from
                  any contracts entered into between you and us, including for
                  billing and collection;
                </li>
                <li>
                  provide you with notices about your account or subscription,
                  including expiration and renewal notices, email-instructions,
                  etc.;
                </li>
                <li>
                  provide you with news, offers, and general information about
                  our services which we offer unless you have opted not to
                  receive such information;
                </li>
                <li>any other purpose with your consent.</li>
              </ul>
            </p>

            <h3>Transfer of Data</h3>
            <p>
              Your information, including Personal Data, may be maintained and
              transferred to computers located outside of your country's
              jurisdiction, and the data protection laws may differ from those
              of your jurisdiction. Your consent to this Privacy Policy followed
              by your submission of such information represents your agreement
              to that transfer.
            </p>

            <h3>Processing data</h3>
            <p>
              We may be required to disclose personal information that we
              collect under certain circumstances, especially if it's mandatory
              by the law or in response to valid requests by public authorities.
              <br />
              Your data may be transferred if our company will be involved in a
              merger, asset sale, or acquisition. Other circumstances of the
              disclosure are only for the purpose for which you provide it, with
              your consent in any other cases, to put your company's logo on our
              website, or to support our business by a third party as a service
              provider, contractors, or other third parties.
            </p>

            <h2>4. Security of Data</h2>
            <p>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable measures to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>

            <h2>5. Retention of Data</h2>
            <p>
              We will only store your personal data for as long as necessary for
              the purposes set out in this Privacy Policy. We will store and use
              your personal information to the extent required to comply with
              our legal obligations, financial settlements, dispute resolution,
              and enforcement of our legal agreements and policies.
            </p>

            <h2>6. We respect GDPR and CPPA </h2>
            <p>
              We respect your rights provided by the{" "}
              <b>
                General Data Protection Regulation and the California Privacy
                Protection Act
              </b>
              , so you have a right to: correct, amend, delete or limit the use
              of your Personal Data, get information about your data we process,
              especially:
              <ul>
                <li>
                  access, update, rectification, or to delete the information we
                  collect;{" "}
                </li>
                <li>object to the processing of your data;</li>
                <li>
                  data portability, we can provide you a copy of your Personal
                  Data in a machine-readable used format;
                </li>
                <li>
                  request the restriction of the processing of personal data;
                </li>
                <li>withdraw your consent at any time;</li>
                <li>be notified - if data has been a breach. </li>
              </ul>
            </p>

            <p>
              Please note that we may ask you to verify your identity before
              responding to such requests. We may not be able to provide Service
              without some necessary data.
              <br />
              You have the right to complain to a Data Protection Authority
              about our collection and use of your Personal Data. For more
              information, please contact your local data protection authority
              in the European Economic Area (EEA).
              <br />
              We don't sell or rent your personal information to any third
              parties for any purpose.
            </p>

            <h2>7. Data Processors</h2>
            <p>
              We may employ third party companies and individuals to upgrade,
              automate the development process of our Service ("Service
              Providers", “Data Processors”), provide Service on our behalf, or
              assist us in analyzing how our Service is used. Third parties have
              access to your Personal Data only to perform these tasks on our
              behalf and are obligated not to disclose or use it for any other
              purpose.
            </p>

            <h2>8. Payments</h2>
            <p>
              We may provide paid products and services within Monitor Uptime.
              In that case, we use third-party services for payment processing.
              We will not store or collect your payment card details. That
              information is provided directly to our third-party payment
              processors (Paddle), whose use of your personal information is
              governed by their Privacy Policy.
            </p>

            <h2>9. Links to Other Sites</h2>
            <p>
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that site. We strongly advise you to review the Privacy Policy of
              every site you visit. We have no control over and assume no
              responsibility for the content, privacy policies, or practices of
              any third party sites or services.
            </p>

            <h2>10. Changes to Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              We advise you to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective since we
              post them on this page.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
