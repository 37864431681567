import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';


import { RootState, TypedDispatch } from '../store';
import { setAxiosAuthToken } from "./utils"

let initToken = null;
if (localStorage.getItem("token")) {
    initToken = localStorage.getItem("token");
    setAxiosAuthToken(initToken);
}

type ProfileType = {
    plan: string;
}

export type UserType = {
    pk: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    profile: ProfileType;
}


const initialState = {
    token: initToken as string | null,
    user: {
        pk: 0,
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        profile: {
            plan: "unknown"
        }
    } as UserType,
    usernameRegisterError: "",
    emailRegisterError: "",
    password1RegisterError: "",
    verifyEmailStatus: "unknown",
    blockUI: false,
};


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken(state, action: PayloadAction<string | null>) {
            state.token = action.payload;
            setAxiosAuthToken(state.token);
            if (state.token) {
                localStorage.setItem("token", state.token);
            } else {
                localStorage.removeItem("token")
            }
        },
        setUserInfo(state, action: PayloadAction<UserType>) {
            state.user = action.payload;
        },
        setUsernameRegisterError(state, action: PayloadAction<string>) {
            state.usernameRegisterError = action.payload;
        },
        setEmailRegisterError(state, action: PayloadAction<string>) {
            state.emailRegisterError = action.payload;
        },
        setPassword1RegisterError(state, action: PayloadAction<string>) {
            state.password1RegisterError = action.payload;
        },
        setVerifyEmailStatus(state, action: PayloadAction<string>) {
            state.verifyEmailStatus = action.payload;
        },
        setBlockUI(state, action: PayloadAction<boolean>) {
            state.blockUI = action.payload;
        }
    },
});

export default authSlice.reducer;

export const {
    setToken,
    setUserInfo,
    setUsernameRegisterError,
    setEmailRegisterError,
    setPassword1RegisterError,
    setVerifyEmailStatus,
    setBlockUI,
} = authSlice.actions;

export const getToken = (state: RootState) => state.auth.token;
export const getUserInfo = (state: RootState) => state.auth.user;
export const getUsernameRegisterError = (state: RootState) => state.auth.usernameRegisterError;
export const getEmailRegisterError = (state: RootState) => state.auth.emailRegisterError;
export const getPassword1RegisterError = (state: RootState) => state.auth.password1RegisterError;
export const getVerifyEmailStatus = (state: RootState) => state.auth.verifyEmailStatus;
export const getBlockUI = (state: RootState) => state.auth.blockUI;


export const register =
    (username: string, email: string, password1: string, password2: string, navigate: NavigateFunction) =>
        async (dispatch: TypedDispatch) => {
            try {
                dispatch(setBlockUI(true));
                // clear all register errors
                dispatch(setUsernameRegisterError(""));
                dispatch(setEmailRegisterError(""));
                dispatch(setPassword1RegisterError(""));

                const url = '/api/auth/register/';
                await axios.post(url, { username, email, password1, password2 });

                // redirect ...
                dispatch(setBlockUI(false));
                navigate("/verify-email-sent");

            } catch (error) {
                dispatch(setBlockUI(false));

                const err = error as AxiosError

                type RegisterErrorType = {
                    non_field_errors?: string[];
                    username?: string[];
                    email?: string[];
                    password1?: string[]
                };

                const data = err.response?.data as RegisterErrorType;

                if (data.username) {
                    dispatch(setUsernameRegisterError(data.username.join(" ")));
                }
                if (data.email) {
                    dispatch(setEmailRegisterError(data.email.join(" ")));
                }
                if (data.password1) {
                    dispatch(setPassword1RegisterError(data.password1.join(" ")));
                }
                if (data.non_field_errors) {
                    toast.error(`Problem during authentication. ${data.non_field_errors}`);
                }

            }
        };



export const verifyEmail =
    (key: string) =>
        async (dispatch: TypedDispatch) => {
            try {
                dispatch(setVerifyEmailStatus("started"));

                const url = "/api/auth/register/verify-email/";
                await axios.post(url, { key });

                dispatch(setVerifyEmailStatus("ok"));
            } catch (error) {
                dispatch(setVerifyEmailStatus("error"));
            }
        };



export const login =
    (email: string, password: string, redirectPath: string, navigate: NavigateFunction) =>
        async (dispatch: TypedDispatch) => {
            try {

                const url = '/api/auth/login/';
                const { data } = await axios.post(url, { email, password });

                dispatch(setToken(data.key));
                // redirect ...
                navigate(redirectPath);

            } catch (error) {
                toast.error("Problem during login. Please try again.");
            }
        };

export const fetchUserInfo =
    () =>
        async (dispatch: TypedDispatch) => {
            try {

                const url = '/api/auth/user/';
                const { data } = await axios.get(url);

                dispatch(setUserInfo(data));

            } catch (error) {
                toast.error("Error occurred when fetching user information");
            }
        };


export const logout =
    (navigate: NavigateFunction) =>
        async (dispatch: TypedDispatch) => {
            try {

                dispatch(setToken(""));
                dispatch(setUserInfo({} as UserType));
                navigate("/");

                const url = '/api/auth/logout/';
                await axios.post(url);

            } catch (error) {
                
            }
        };


export const changePassword =
    (oldPassword: string, password1: string, password2: string) =>
        async (dispatch: TypedDispatch) => {
            try {

                const url = '/api/auth/password/change/';
                const data = {
                    new_password1: password1,
                    new_password2: password2,
                    old_password: oldPassword
                }
                await axios.post(url, data);

                toast.success("Password changed successfully")

            } catch (error) {

                const err = error as AxiosError

                type RegisterErrorType = {
                    non_field_errors?: string[];
                    new_password1?: string[];
                    new_password2?: string[];
                    old_password?: string[]
                };

                const data = err.response?.data as RegisterErrorType;

                if (data.non_field_errors) {
                    toast.error(data.non_field_errors.join(" "));
                }
                if (data.new_password1) {
                    toast.error(data.new_password1.join(" "));
                }
                if (data.new_password2) {
                    toast.error(data.new_password2.join(" "));
                }
                if (data.old_password) {
                    toast.error(data.old_password.join(" "));
                }
            }
        };


export const deleteAccount =
    (navigate: NavigateFunction) =>
        async (dispatch: TypedDispatch) => {
            try {

                const url = '/api/auth/delete-account/';
                await axios.post(url);

                dispatch(setToken(""));
                dispatch(setUserInfo({} as UserType));
                navigate("/");
            } catch (error) {
                const err = error as AxiosError

                type RegisterErrorType = {
                    msg?: string;
                };

                const data = err.response?.data as RegisterErrorType;

                if (data.msg) {
                    toast.error(data.msg);
                } else {
                    toast.error("Problem during account removal. Please try again.");
                }
            }
        };


export const checkSubscription =
    () =>
        async (dispatch: TypedDispatch) => {
            try {

                const url = '/api/auth/check-subscription/';
                await axios.post(url);
                dispatch(fetchUserInfo());
            } catch (error) {
                // fail silent
            }
        };


export const cancelSubscription =
    () =>
        async (dispatch: TypedDispatch) => {
            try {
                const url = '/api/auth/cancel-subscription/';
                await axios.post(url);
                dispatch(fetchUserInfo());
            } catch (error) {
                toast.error("Problem during canceling subscription. Please try again or contact us be email contact@monitor-uptime.com for help.")
            }
        };


export const resetPassword =
    (email: string, navigate: NavigateFunction) =>
        async (dispatch: TypedDispatch) => {
            try {

                const url = '/api/auth/password/reset/';
                const data = { email }
                await axios.post(url, data);

                navigate("/reset-password-email-sent")

            } catch (error) {
                toast.error("Problems with password reset. Please try again or contact administrator.");
            }
        };



export const setNewPassword =
    (uid: string, token: string, password1: string, password2: string, navigate: NavigateFunction) =>
        async (dispatch: TypedDispatch) => {
            try {

                const url = '/api/auth/password/reset/confirm/';
                const data = {
                    uid,
                    token,
                    new_password1: password1,
                    new_password2: password2,
                }
                await axios.post(url, data);

                toast.success("New password set successfully");

                navigate("/login");

            } catch (error) {

                const err = error as AxiosError

                type RegisterErrorType = {
                    non_field_errors?: string[];
                    uid?: string[];
                    token?: string[];
                    new_password1?: string[];
                    new_password2?: string[];

                };

                const data = err.response?.data as RegisterErrorType;

                if (data.non_field_errors) {
                    toast.error(data.non_field_errors.join(" "));
                }
                if (data.uid) {
                    toast.error("UID " + data.uid.join(" "));
                }
                if (data.token) {
                    toast.error("Token " + data.token.join(" "));
                }
                if (data.new_password1) {
                    toast.error("New Password 1 " + data.new_password1.join(" "));
                }
                if (data.new_password2) {
                    toast.error("New Password 2 " + data.new_password2.join(" "));
                }
            }
        };

