import { useEffect } from "react";
import { useSelector } from "react-redux";
import ChangePassword from "../components/ChangePassword";
import DeleteAccount from "../components/DeleteAccount";
import Subscription from "../components/Subscription";
import { checkSubscription, getUserInfo } from "../slices/authSlice";
import { useAppDispatch } from "../store";

export default function SettingsView() {
  const dispatch = useAppDispatch();
  const user = useSelector(getUserInfo);

  useEffect(() => {
    dispatch(checkSubscription());
  }, [dispatch]);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2><i className="bi bi-sliders"></i>{" "}User settings</h2>
            <hr />
            <p><b>Username:</b> {user.username}</p>
            <p><b>Email:</b> {user.email}</p>
            <Subscription user={user}/>
            <hr />
            <ChangePassword />
            <hr />
            <DeleteAccount user={user}/>
          </div>
        </div>
      </div>
    </div>
  );
}
