import { useState } from "react";
import { changePassword } from "../slices/authSlice";
import { useAppDispatch } from "../store";


export default function ChangePassword() {
  const dispatch = useAppDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  return (
    <div>
      <h3>Change Password</h3>
      <div className="my-2">
        <input
          type="password"
          className="form-control form-control-lg"
          id="oldPassword"
          placeholder="Old Password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
      </div>

      <div className="my-2">
        <input
          type="password"
          className="form-control form-control-lg"
          id="password1"
          placeholder="New Password"
          value={password1}
          onChange={(e) => setPassword1(e.target.value)}
        />
      </div>
      <div className="my-2">
        <input
          type="password"
          className="form-control form-control-lg"
          id="password2"
          placeholder="Repeat New Password"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />
      </div>

      <button
        className="btn btn-primary"
        onClick={(e) => {
          dispatch(changePassword(oldPassword, password1, password2));
        }}
        disabled={
          oldPassword === "" || password1 === "" || password1 !== password2
        }
      >
        Change Password
      </button>
    </div>
  );
}
