import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState, TypedDispatch } from "../store";

export type AlertType = {
  id: number;
  notification: string;
  msg: string;
  created_at: Date;
};

const initialState = {
  alerts: [] as AlertType[],
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlerts(state, action: PayloadAction<AlertType[]>) {
      state.alerts = action.payload;
    },
  },
});

export default alertSlice.reducer;

export const {
  setAlerts,
} = alertSlice.actions;

export const getAlerts = (state: RootState) => state.alert.alerts;

export const fetchAlerts = (monitorId: string | number | undefined) => async (dispatch: TypedDispatch) => {
  try {
    const url = `/api/${monitorId}/alerts`;
    const response = await axios.get(url);
    dispatch(setAlerts(response.data));
  } catch (error) {
    toast.error("Cannot fetch alerts from server");
  }
};

export const addAlert =
  (
    monitorId: string | number | undefined,
    alertId: number | undefined, // not used, just to have the same arguments as updateAlert
    notification: string,
    msg: string,
    navigate: NavigateFunction
  ) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = `/api/${monitorId}/alerts/`;
        await axios.post(url, {
          notification,
          msg
        });
        toast.success("New alert added");
        navigate("/app/alerts");
      } catch (error) {
        toast.error("Cannot add a new alert");
      }
    };

export const deleteAlert =
  (monitorId: string | number | undefined, alertId: number) => async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/${monitorId}/alerts/${alertId}`;
      await axios.delete(url);
      toast.success("Alert deleted");
      dispatch(fetchAlerts(monitorId));
    } catch (error) {
      toast.error("Cannot delete alert");
    }
  };


export const updateAlert =
  (
    monitorId: string | undefined,
    alertId: number | undefined,
    notification: string,
    msg: string,
    navigate: NavigateFunction
  ) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = `/api/${monitorId}/alerts/${alertId}/`;
        await axios.patch(url, {
          notification,
          msg,
        });
        toast.success("Alert updated");
        navigate("/app/alerts");
      } catch (error) {
        toast.error("Cannot update alert");
      }
    };
