import { MonitorType, setSelectedMonitorId } from "../slices/monitorSlice";
import { useAppDispatch } from "../store";

type Props = {
  selectedId: number | undefined;
  monitors: MonitorType[];
};

export const getMonitorIdSelection = (
  selectedMonitorId: number | undefined,
  monitors: MonitorType[]
) => {
  let selectedId = undefined as number | undefined;
  if (selectedMonitorId) {
    selectedId = selectedMonitorId;
  }
  if (!selectedId && monitors.length > 0) {
    selectedId = monitors[0].id;
  }
  return selectedId;
};

export default function MonitorDropdown({
  selectedId,
  monitors,
}: Props) {
  const dispatch = useAppDispatch();

  const options = monitors.map((monitor) => {
    return (
      <option value={monitor.id} key={monitor.id}>
        {monitor.title} ({monitor.request_method} {monitor.endpoint} every{" "}
        {monitor.interval / 60} min)
      </option>
    );
  });

  return (
    <div className="row">
      <div className="col-md-10 offset-md-1">
        <hr style={{ marginTop: "0px" }} />

        <div className="my-2">
          <select
            className="form-select"
            value={selectedId}
            onChange={(e) =>
              dispatch(setSelectedMonitorId(parseInt(e.target.value)))
            }
          >
            {options}
          </select>
        </div>
      </div>
    </div>
  );
}
