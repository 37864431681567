import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUserInfo, getUserInfo } from "../slices/authSlice";
import {
  addMonitor,
  fetchMonitors,
  getMonitors,
  MonitorType,
  updateMonitor,
} from "../slices/monitorSlice";
import { useAppDispatch } from "../store";

interface MonitorLocation {
  state: {
    monitor: MonitorType;
  };
}

export default function AddMonitorView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const monitors = useSelector(getMonitors);
  const user = useSelector(getUserInfo);

  const { state } = useLocation() as unknown as MonitorLocation;
  let updateView = false;
  let monitor = {} as MonitorType;

  let defaultTitle = "";
  let defaultUrl = "";
  let defaultInterval = 300;
  let defaultRequestMethod = "GET";
  let monitorId: undefined | number = undefined;
  if (state) {
    updateView = true;
    monitor = state.monitor;
    defaultTitle = monitor.title;
    defaultUrl = monitor.endpoint;
    defaultInterval = monitor.interval;
    defaultRequestMethod = monitor.request_method;
    monitorId = monitor.id;
  }

  const [title, setTitle] = useState(defaultTitle);
  const [url, setUrl] = useState(defaultUrl);
  const [interval, setInterval] = useState(defaultInterval);
  const [requestMethod, setRequestMethod] = useState(defaultRequestMethod);

  useEffect(() => {
    dispatch(fetchUserInfo());
    dispatch(fetchMonitors());
  }, [dispatch]);

  let showForm = true;
  let upgrade = false;
  let proLimit = false;
  if (user.profile.plan === "free" && monitors.length >= 1) {
    showForm = false;
    upgrade = true;
  }
  if (user.profile.plan === "pro" && monitors.length >= 20) {
    showForm = false;
    proLimit = true;
  }

  const okFunction = updateView ? updateMonitor : addMonitor;

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            {!updateView && <h2 style={{ float: "left" }}>Add Monitor</h2>}
            {updateView && <h2 style={{ float: "left" }}>Update Monitor</h2>}
          </div>

          {!updateView && upgrade && (
            <div className="col-md-10 offset-md-1">
              <p>
                You reached <b>free</b> plan limit. Please upgrade to <b>Pro</b>{" "}
                plan to add more monitors.
              </p>
              <button
                className="btn btn-success"
                onClick={(e) => navigate("/app/settings")}
              >
                Upgrade to Pro
              </button>
              <button
                className="btn btn-secondary my-2 mx-2"
                onClick={(e) => {
                  navigate("/app");
                }}
              >
                Cancel
              </button>
            </div>
          )}
          {!updateView && proLimit && (
            <div className="col-md-10 offset-md-1">
              <p>
                You reached <b>Pro</b> plan limit. Please contact us at
                contact@saasitive.com for help.
              </p>
            </div>
          )}

          {(showForm || updateView) && (
            <div className="col-md-10 offset-md-1">
              <form
                className="form-signin"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <div className="my-2">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control form-control"
                    placeholder="My monitor"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="my-2">
                  <label>URL</label>
                  <input
                    type="text"
                    className="form-control form-control"
                    placeholder="https://example.com"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
                <div className="my-2">
                  <label>Request method</label>

                  <select
                    className="form-select"
                    value={requestMethod}
                    onChange={(e) => setRequestMethod(e.target.value)}
                  >
                    <option value="GET">GET request</option>
                    <option value="POST">POST request</option>
                  </select>
                </div>

                <div className="my-2">
                  <label>Monitoring interval</label>

                  <select
                    className="form-select"
                    value={interval}
                    onChange={(e) => setInterval(parseInt(e.target.value))}
                  >
                    <option value="60">1 minute</option>
                    <option value="120">2 minutes</option>
                    <option value="180">3 minutes</option>
                    <option value="240">4 minutes</option>
                    <option value="300">5 minutes</option>
                    <option value="600">10 minutes</option>
                    <option value="900">15 minutes</option>
                    <option value="1800">30 minutes</option>
                    <option value="3600">1 hour</option>
                  </select>
                </div>

                <button
                  className="w-25 btn btn-success my-2"
                  onClick={(e) => {
                    dispatch(
                      okFunction(
                        monitorId,
                        title,
                        url,
                        interval,
                        requestMethod,
                        navigate
                      )
                    );
                  }}
                >
                  OK
                </button>
                <button
                  className="w-25 btn btn-secondary my-2 mx-2"
                  onClick={(e) => {
                    navigate("/app");
                  }}
                >
                  Cancel
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
