import ReactTooltip from "react-tooltip";
import { MonitorDataType } from "../slices/pageSlice";

type Props = {
  monitor: MonitorDataType;
};

const getColor = (uptime: number) => {
  if (uptime === 100) {
    return "#7ed957";
  } else if (uptime > 95) {
    return "#ff712b";
  } else {
    return "#ff3e86";
  }
};

export default function StatusBars({ monitor }: Props) {
  
  let bars = monitor.x_data.map((x, index) => {
    return (
      <div
        style={{
          display: "inline-block",
        }}
        key={`bar-${index}`}
      >
        <div
          data-tip
          data-for={`tip-${index}`}
          style={{
            marginRight: "4px",
            backgroundColor: getColor(monitor.y_percent_correct[index]),
            width: "10px",
            height: "50px",
            borderRadius: "3px",
          }}
        ></div>

        <ReactTooltip id={`tip-${index}`} aria-haspopup="true">
          Uptime {monitor.y_percent_correct[index]}%
          <br />
          Average reponse time {monitor.y_avg_response_time[index]}ms
          <br />
          {x.toLocaleString()}
        </ReactTooltip>
      </div>
    );
  });

  return <div>{bars}</div>;
}
