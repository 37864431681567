import { useNavigate } from "react-router-dom";

export default function PricingView() {
  let navigate = useNavigate();
  return (
    <div>
      <div className="container">
        <div className="pricing-header p-3 px-5 mx-5 pb-md-4 text-center">
          <h1 className="display-4 fw-normal">Pricing</h1>
          <p className="fs-5 text-muted">
            Quickly setup monitoring for your servers
          </p>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
            <div className="col-md-3 offset-md-1">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">Free</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">
                    $0<small className="text-muted fw-light">/mo</small>
                  </h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>1 monitor</li>

                    <li>Email support</li>
                  </ul>
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-outline-primary"
                    onClick={(e) => {
                      navigate("/register");
                    }}
                  >
                    Sign up for free
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4 rounded-3 shadow-sm border-primary">
                <div className="card-header py-3 text-white bg-primary border-primary">
                  <h4 className="my-0 fw-normal">Pro</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">
                    $19<small className="text-muted fw-light">/mo</small>
                  </h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>20 monitors</li>
                    <li>Anomaly detection</li>
                    <li>Public status page with your branding</li>
                    <li>Email support</li>
                  </ul>
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-primary"
                    onClick={(e) => {
                      navigate("/register");
                    }}
                  >
                    Get started
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">SaaS Geek</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">
                    $199<small className="text-muted fw-light"></small>
                  </h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>Build & host your own monitoring server</li>
                    <li>Access to step-by-step tutorial</li>
                    <li>Access to source code</li>
                    <li>Email support</li>
                  </ul>
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-primary"
                    onClick={(e) =>
                      window.open("https://saasitive.com/pricing/", "_blank")
                    }
                  >
                    Learn more
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-5 offset-md-1">
            <h4>What type of payments do you accept?</h4>
            <p>
              We use Paddle as our payment provider. You can pay with
              Credit/Debit Card or PayPal.
            </p>

            <h4>What support do you provide?</h4>
            <p>You get dedicated support by email.</p>

            <h4>Can I get refund?</h4>
            <p>
              You can get full refund up to 30 days after purchase. Please just
              let us know (contact@monitor-uptime.com).
            </p>
          </div>
          <div className="col-md-5">
            <h4>Can I cancel my plan?</h4>
            <p>
              You can cancel your subscription any time from your account and
              let it expire back to the Free Plan.
            </p>

            <h4>Questions</h4>
            <p>
              Please contact us for ny further questions by email
              contact@monitor-uptime.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
