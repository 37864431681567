export default function ResetPasswordEmailSentView() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1>Reset Password Email Sent</h1>

            <p>
              Please check your email. We send you password reset link. Please
              double-check your inbox. The email might be in the spam folder.
            </p>

            <p>
              In case of any problems, please contact us by email
              contact@monitor-uptime.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
