import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="container">
      <footer className="py-3 my-4">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <li className="nav-item">
            <Link to="/" className="nav-link px-2 text-muted">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/pricing" className="nav-link px-2 text-muted">
              Pricing
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-link px-2 text-muted">
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-link px-2 text-muted">
              Contact
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/register" className="nav-link px-2 text-muted">
              Sign Up
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/login" className="nav-link px-2 text-muted">
              Login
            </Link>
          </li>
        </ul>
        <ul className="nav justify-content-center pb-2">
          <li className="nav-item">
            <Link to="/privacy-policy" className="nav-link px-2 text-muted">
              Privacy Policy
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/terms-of-service" className="nav-link px-2 text-muted">
              Terms of Service
            </Link>
          </li>
        </ul>
        <p className="text-center text-muted">© MLJAR Sp. z o.o.</p>
      </footer>
    </div>
  );
}
