export default function AboutView() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h1>About</h1>
            <p>
              Monitoring server uptime is important tool. There are many
              services for uptime monitoring available on the market. Why we
              created one more?
            </p>
            <p>
              1️⃣ We would like to create a service that will detect problems
              (potential downtime) before they occur. We are using Machine
              Learning algorithm for detecting anomalies in server response
              time. It gives us an endge over existing solutions.
            </p>
            <p>
              2️⃣ We want to create a tutorial how to build SaaS service from
              scratch with React and Django. You can learn how to build this
              service by yourself! The bulding steps are descibed in our{" "}
              <a href="https://saasitive.com/react-django-tutorial/">
                React and Django tutorial
              </a>
              .
            </p>
            <h2>About us</h2>
            <p>
              We are passionate about software engineering and data science. Our
              projects:
            </p>
            <p>
              <div className="row">
                <div className="col-sm-4">
                  <div className="card" style={{ width: "100%" }}>
                    <img
                      src="https://raw.githubusercontent.com/mljar/visual-identity/main/mercury/turn-notebook-to-web-app.png"
                      className="card-img-top"
                      alt="..."
                      style={{ height: "200px" }}
                    />
                    <div className="card-body">
                      <h5 className="card-title">Mercury</h5>
                      <p className="card-text">
                        Open-source framework to turn Jupyter Notebooks to web
                        applications
                      </p>
                      <a
                        href="https://github.com/mljar/mercury"
                        target="_blank"
                        className="btn btn-primary"
                        rel="noreferrer"
                      >
                        Learn more
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card" style={{ width: "100%" }}>
                    <img
                      src="https://raw.githubusercontent.com/mljar/mljar-examples/master/media/AutoML_overview_mljar_v3.svg"
                      className="card-img-top"
                      alt="..."
                      style={{ height: "200px" }}
                    />
                    <div className="card-body">
                      <h5 className="card-title">MLJAR AutoML</h5>
                      <p className="card-text">
                        Python package for Automated Machine Learning (AutoML)
                        on tabular data.
                      </p>
                      <a
                        href="https://github.com/mljar/mljar-supervised"
                        target="_blank"
                        className="btn btn-primary"
                        rel="noreferrer"
                      >
                        Learn more
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card" style={{ width: "100%" }}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/deploy-machine-learning-with-django.png"
                      }
                      className="card-img-top"
                      alt="..."
                      style={{ height: "200px" }}
                    />
                    <div className="card-body">
                      <h5 className="card-title">Deploy ML with Django</h5>
                      <p className="card-text">
                        Step-by-step tutorial how to deploy Machine Learning
                        models with Django
                      </p>
                      <a
                        href="https://www.deploymachinelearning.com/"
                        target="_blank"
                        className="btn btn-primary"
                        rel="noreferrer"
                      >
                        Learn more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </p>
            <p>
              <h2>Hi there 👋</h2>

              <img
                alt="amazing team"
                src={process.env.PUBLIC_URL + "/team.jpg"}
                style={{
                  width: "40%",
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
