import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteMonitor,
  fetchDailyStats,
  fetchLatestRequests,
  MonitorType,
  toggleMonitorStatus,
} from "../slices/monitorSlice";
import { useAppDispatch } from "../store";

type Props = {
  selectedId: number | undefined;
  selectedMonitor: MonitorType | undefined;
};

export default function MonitorButtons({ selectedId, selectedMonitor }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <div>
      <button
        style={{ float: "right" }}
        className="btn btn-outline-danger "
        onClick={(e) => {
          if (selectedId && window.confirm("Delete permanently monitor?")) {
            dispatch(deleteMonitor(selectedId));
          }
        }}
        title="Remove monitor and all its data"
      >
        <i className="bi bi-trash"></i> Delete
      </button>
      {selectedMonitor && (
        <button
          style={{ float: "right" }}
          className="btn btn-outline-secondary mx-2"
          onClick={(e) => {
            navigate("/app/add-monitor", {
              state: {
                monitor: selectedMonitor,
              },
            });
          }}
          title="Change current monitor"
        >
          <i className="bi bi-pencil"></i> Edit
        </button>
      )}
      <button
        style={{ float: "right" }}
        className="btn btn-outline-secondary "
        onClick={(e) => {
          if (selectedId) {
            dispatch(fetchLatestRequests(selectedId));
            dispatch(fetchDailyStats(selectedId));
            toast.info("Data updated");
          }
        }}
        title="Load latest data"
      >
        <i className="bi bi-arrow-clockwise"></i> Refresh
      </button>

      {selectedMonitor && selectedMonitor.status !== "pause" && (
        <button
          style={{ float: "right" }}
          className="btn btn-outline-secondary mx-2"
          onClick={(e) => {
            if (selectedId) {
              dispatch(toggleMonitorStatus(selectedId, "ready"));
            }
          }}
          title="Pause monitoring"
        >
          <i className="bi bi-pause"></i> Pause
        </button>
      )}
      {selectedMonitor && selectedMonitor.status === "pause" && (
        <button
          style={{ float: "right" }}
          className="btn btn-primary mx-2"
          onClick={(e) => {
            if (selectedId) {
              dispatch(toggleMonitorStatus(selectedId, "pause"));
            }
          }}
          title="Start monitor after pause"
        >
          <i className="bi bi-play"></i> Restore
        </button>
      )}
    </div>
  );
}
