import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PublicAlertComponent from "../components/PublicAlertComponent";
import PublicMonitorComponent from "../components/PublicMonitorComponent";
import {
  fetchPublicPage,
  getPublicPage,
} from "../slices/pageSlice";
import { useAppDispatch } from "../store";

export default function StatusPagePublicView() {
  const dispatch = useAppDispatch();
  const page = useSelector(getPublicPage);
  let { slug } = useParams();

  useEffect(() => {
    if (slug !== undefined) {
      dispatch(fetchPublicPage(slug));
    }
  }, [dispatch, slug]);

  let monitorsComponent;
  if (page.monitors_data) {
    monitorsComponent = page.monitors_data.map((monitor, index) => {
      return <PublicMonitorComponent monitor={monitor} key={index} />;
    });
  }

  let alertsComponent;
  if (page.alerts_data) {
    alertsComponent = page.alerts_data.map((alert, index) => {
      return <PublicAlertComponent alert={alert} key={index} />;
    });
  }

  return (
    <div>
      <div className="container">
        <div className="row py-4">
          <div className="col-md-10 offset-md-1">
            <h1>{page.title}</h1>
          </div>
        </div>

        <div className="row py-4">
          <div className="col-md-10 offset-md-1">{page.description}</div>
        </div>

        <div className="row py-4">
          <div className="col-md-10 offset-md-1">
            <h3>
              <i className="bi bi-bar-chart"></i> Monitors
            </h3>
            {monitorsComponent && monitorsComponent.length === 0 && (
              <p>No monitors available</p>
            )}
            {monitorsComponent}
          </div>
        </div>

        <div className="row py-4">
          <div className="col-md-10 offset-md-1">
            <h3>
              <i className="bi bi-bell"></i> Alerts
            </h3>
            {alertsComponent && alertsComponent.length === 0 && (
              <p>No alerts available</p>
            )}
            {alertsComponent}
          </div>
        </div>

        <div className="row py-4">
          <div className="col-md-10 offset-md-1">
            <small>
              Provided by{" "}
              <a href="https://monitor-uptime.com" target="_blank">
                monitor-uptime.com
              </a>
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}
