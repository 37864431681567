import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState, TypedDispatch } from "../store";

export type LatestRequestsDataType = {
  x_data: string[];
  y_data: number[];
  status_data: string[];
};

export type DailyStatsDataType = {
  x_data: string[];
  y_percent_correct: number[];
  y_avg_response_time: number[];
  total_requests_count: number;
  avg_percent_correct: number;
  total_failure_period: number;
  avg_response_time: number;
};

export type MonitorType = {
  id: number;
  title: string;
  endpoint: string;
  request_method: string;
  header_data: string;
  interval: number;
  expected_response_status: number;
  expected_response_data: string;
  status: string;
  ml_enabled: string;
};

const initialState = {
  monitors: [] as MonitorType[],
  selectedMonitor: {} as MonitorType | undefined,
  selectedMonitorId: undefined as number | undefined,
  latestRequests: {} as LatestRequestsDataType,
  dailyStats: {} as DailyStatsDataType,
};

const monitorSlice = createSlice({
  name: "monitor",
  initialState,
  reducers: {
    setMonitors(state, action: PayloadAction<MonitorType[]>) {
      state.monitors = action.payload;
      if (state.selectedMonitorId) {
        state.selectedMonitor = state.monitors.find(
          (m) => m.id === state.selectedMonitorId
        );
      }
    },
    setSelectedMonitorId(state, action: PayloadAction<number>) {
      state.selectedMonitorId = action.payload;
      state.selectedMonitor = state.monitors.find(
        (m) => m.id === state.selectedMonitorId
      );
    },
    setLatestRequests(state, action: PayloadAction<LatestRequestsDataType>) {
      state.latestRequests = action.payload;
    },
    setDailyStats(state, action: PayloadAction<DailyStatsDataType>) {
      state.dailyStats = action.payload;
    },
  },
});

export default monitorSlice.reducer;

export const {
  setMonitors,
  setSelectedMonitorId,
  setLatestRequests,
  setDailyStats,
} = monitorSlice.actions;

export const getMonitors = (state: RootState) => state.monitor.monitors;
export const getSelectedMonitor = (state: RootState) =>
  state.monitor.selectedMonitor;
export const getSelectedMonitorId = (state: RootState) =>
  state.monitor.selectedMonitorId;
export const getLatestRequests = (state: RootState) =>
  state.monitor.latestRequests;
export const getDailyStats = (state: RootState) => state.monitor.dailyStats;

export const fetchMonitors = () => async (dispatch: TypedDispatch) => {
  try {
    const url = "/api/monitors/";
    const response = await axios.get(url);
    dispatch(setMonitors(response.data));
  } catch (error) {
    toast.error("Cannot fetch monitors from server");
  }
};

export const addMonitor =
  (
    id: number | undefined,
    title: string,
    endpoint: string,
    interval: number,
    request_method: string,
    navigate: NavigateFunction
  ) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = "/api/monitors/";
        await axios.post(url, {
          title,
          endpoint,
          interval,
          request_method,
        });
        toast.success("New monitor added");
        navigate("/app");
      } catch (error) {
        toast.error("Cannot add a new monitor");
      }
    };

export const updateMonitor =
  (
    id: number | undefined,
    title: string,
    endpoint: string,
    interval: number,
    request_method: string,
    navigate: NavigateFunction
  ) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = `/api/monitors/${id}/`;
        await axios.patch(url, {
          title,
          endpoint,
          interval,
          request_method,
        });
        toast.success("Monitor updated");
        navigate("/app");
      } catch (error) {
        toast.error("Cannot update monitor");
      }
    };

export const deleteMonitor =
  (monitorId: number) => async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/monitors/${monitorId}`;
      await axios.delete(url);
      toast.success("Monitor deleted");
      dispatch(fetchMonitors());
    } catch (error) {
      toast.error("Cannot delete monitor");
    }
  };

export const fetchLatestRequests =
  (monitorId: number) => async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/latest-requests/${monitorId}`;
      const response = await axios.get(url);
      dispatch(setLatestRequests(response.data));
    } catch (error) {
      toast.error("Cannot fetch last requests data");
    }
  };

export const fetchDailyStats =
  (monitorId: number) => async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/daily-stats/${monitorId}`;
      const response = await axios.get(url);
      dispatch(setDailyStats(response.data));
    } catch (error) {
      toast.error("Cannot fetch daily stats");
    }
  };

export const toggleMonitorStatus =
  (monitorId: number, status: string) => async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/toggle-monitor/${monitorId}`;
      await axios.post(url);
      dispatch(fetchMonitors());
      if (status === "pause") {
        toast.info("Monitor is running");
      } else {
        toast.info("Monitor stopped");
      }
    } catch (error) {
      toast.error("Cannot toggle monitor status");
    }
  };

export const toggleMonitorML =
  (monitorId: number) => async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/toggle-ml-monitor/${monitorId}`;
      await axios.post(url);
      dispatch(fetchMonitors());
    } catch (error) {
      toast.error("Cannot toggle Anomaly Detection");
    }
  };
