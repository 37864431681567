import Plot from "react-plotly.js";
import {
  DailyStatsDataType,
  LatestRequestsDataType,
} from "../slices/monitorSlice";

type Props = {
  dailyStats: DailyStatsDataType;
  latestRequests: LatestRequestsDataType;
};

export default function MonitorCharts({ dailyStats, latestRequests }: Props) {
  return (
    <div>
      {!dailyStats.y_percent_correct && (
        <div className="py-2">
          <h4>{`Uptime`}</h4>
          <p>No data</p>
        </div>
      )}
      {dailyStats.y_percent_correct && (
        <div className="py-2">
          <h4>{`Uptime (last ${dailyStats.y_percent_correct.length} days)`}</h4>

          <Plot
            data={[
              {
                name: "Uptime",
                x: dailyStats.x_data,
                y: dailyStats.y_percent_correct,
                type: "bar",
                marker: { color: "#2ca02c" },
              },
              {
                name: "Downtime",
                x: dailyStats.x_data,
                y: dailyStats.y_percent_correct.map((x) => 100 - x),
                type: "bar",
                marker: { color: "#d62728" },
              },
            ]}
            useResizeHandler
            layout={{
              barmode: "stack",
              margin: {
                l: 50,
                r: 0,
                b: 50,
                t: 50,
                pad: 4,
              },
            }}
            style={{ width: "100%" }}
          />
        </div>
      )}

      {!dailyStats.y_avg_response_time && (
        <div className="py-2">
          <h4>{`Average Response Time`}</h4>
          <p>No data</p>
        </div>
      )}
      {dailyStats.y_avg_response_time && (
        <div className="py-2">
          <h4>{`Average Response Time (last ${dailyStats.y_avg_response_time.length} days)`}</h4>
          <Plot
            data={[
              {
                x: dailyStats.x_data,
                y: dailyStats.y_avg_response_time,
                type: "scatter",
                mode: "lines+markers",
              },
            ]}
            useResizeHandler
            layout={{
              margin: {
                l: 50,
                r: 0,
                b: 50,
                t: 50,
                pad: 4,
              },
            }}
            style={{ width: "100%" }}
          />
        </div>
      )}

      {!latestRequests.x_data && (
        <div className="py-2">
          <h4>{`Response Time`}</h4>
          <p>No data</p>
        </div>
      )}
      {latestRequests.x_data && (
        <div className="py-2">
          <h4>
            {`Response Time (last ${latestRequests.x_data.length}
                      requests)`}
          </h4>
          <Plot
            data={[
              {
                x: latestRequests.x_data,
                y: latestRequests.y_data,
                text: latestRequests.status_data,
                type: "scatter",
                mode: "lines+markers",
              },
            ]}
            useResizeHandler
            layout={{
              margin: {
                l: 50,
                r: 0,
                b: 50,
                t: 50,
                pad: 4,
              },
            }}
            style={{ width: "100%" }}
          />
        </div>
      )}

      {!latestRequests.x_data && (
        <div className="py-2">
          <h4>{`Response Time Distribution`}</h4>
          <p>No data</p>
        </div>
      )}
      {latestRequests.x_data && (
        <div className="py-2">
          <h4>
            {`Response Time Distribution 
            (last ${latestRequests.x_data.length} requests)`}
          </h4>
          <Plot
            data={[
              {
                x: latestRequests.y_data,
                type: "histogram",
              },
            ]}
            useResizeHandler
            layout={{
              margin: {
                l: 50,
                r: 0,
                b: 50,
                t: 50,
                pad: 4,
              },
            }}
            style={{ width: "100%" }}
          />
        </div>
      )}
    </div>
  );
}
