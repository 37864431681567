import { useNavigate } from "react-router-dom";
import { AlertType, deleteAlert } from "../slices/alertSlice";
import { useAppDispatch } from "../store";

type Props = {
  alert: AlertType;
  monitorId: string | number | undefined;
};

export default function AlertComponent({ alert, monitorId }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <div>
      <p>
        <big>
          {alert.notification === "alert" && (
            <i className="bi bi-exclamation-circle"></i>
          )}
          {alert.notification === "warning" && (
            <i className="bi bi-info-circle"></i>
          )}
          {alert.notification === "note" && <i className="bi bi-card-text"></i>}{" "}
          {alert.msg}
        </big>
        <br />
        <small>{alert.created_at.toLocaleString()}</small>{" "}
        <button
          className="btn btn-sm  "
          onClick={(e) => {
            navigate(`/app/add-alert/${monitorId}`, {
              state: {
                alert: alert,
              },
            });
          }}
          title="Edit alert"
        >
          <i className="bi bi-pencil"></i> Edit
        </button>
        <button
          className="btn btn-sm"
          onClick={(e) => {
            dispatch(deleteAlert(monitorId, alert.id));
          }}
          title="Delete alert"
        >
          <i className="bi bi-trash"></i> Delete
        </button>
      </p>
    </div>
  );
}
