import { useEffect } from "react";
import { useSelector } from "react-redux";
import MonitorDropdown, {
  getMonitorIdSelection,
} from "../components/MonitorDropdown";
import { fetchML, getML } from "../slices/mlSlice";
import {
  fetchMonitors,
  getMonitors,
  getSelectedMonitor,
  getSelectedMonitorId,
  setSelectedMonitorId,
  toggleMonitorML,
} from "../slices/monitorSlice";
import { useAppDispatch } from "../store";

export default function MLView() {
  const dispatch = useAppDispatch();
  const monitors = useSelector(getMonitors);
  const ml = useSelector(getML);
  const selectedMonitorId = useSelector(getSelectedMonitorId);
  const selectedMonitor = useSelector(getSelectedMonitor);

  useEffect(() => {
    dispatch(fetchMonitors());
  }, [dispatch]);

  let selectedId = getMonitorIdSelection(selectedMonitorId, monitors);

  useEffect(() => {
    if (selectedId) {
      dispatch(fetchML(selectedId));

      if (selectedMonitor && selectedId !== selectedMonitor.id) {
        dispatch(setSelectedMonitorId(selectedId));
      }
    }
  }, [dispatch, selectedId, selectedMonitor]);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2>
              <i className="bi bi-cpu"></i> Anomaly Detection
            </h2>
          </div>
        </div>

        <MonitorDropdown
          selectedId={selectedId}
          monitors={monitors}
        />

        <div className="row">
          <div className="col-md-10 offset-md-1">
            {Object.keys(ml).length === 0 && <p>No ML models available</p>}

            {Object.keys(ml).length > 0 && (
              <div>
                <div className="row py-2">
                  <div className="col-md-4">
                    <div className=" boxes">
                      <b>MODEL</b>
                      <br />
                      <b>{ml.info.split("(")[0]}</b>
                      <br />
                      {ml.info.split("(")[1].slice(0, -1)}
                    </div>
                  </div>

                  <div className="col-md-4  ">
                    <div className=" boxes">
                      <b>WARNINGS</b>
                      <br />

                      <h2>{ml.warnings_count}</h2>
                    </div>
                  </div>

                  <div className="col-md-4  ">
                    <div className="boxes">
                      <b>TRAINING SAMPLES</b>
                      <br />
                      {ml.samples !== undefined && ml.samples !== null && <h2>{ml.samples.toLocaleString()}</h2>}
                    </div>
                  </div>

                  <small style={{ color: "dimgray", fontFamily: "Monospace" }}>
                    {ml.created_at.toLocaleString()}
                  </small>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row py-2 px-3">
          <div className="col-md-10 offset-md-1 boxes-left">
            {selectedMonitor !== undefined &&
              Object.keys(selectedMonitor).length > 0 &&
              selectedMonitor.ml_enabled === "ML_ENABLED" && (
                <div>
                  <h5>
                    <i className="bi bi-toggles"></i> ML Configuration
                  </h5>
                  <p>
                    Early detection of server down is <b>enabled</b>. You can switch
                    off anomaly detection for this monitor by clicking below
                    button. You can enable it back whenever you want.
                  </p>
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      if (selectedId !== undefined) {
                        dispatch(toggleMonitorML(selectedId));
                      }
                    }}
                  >
                    Disable Anomaly Detection
                  </button>
                </div>
              )}
              {selectedMonitor !== undefined &&
              Object.keys(selectedMonitor).length > 0 &&
              selectedMonitor.ml_enabled === "ML_DISABLED" && (
                <div>
                  <h5>
                    <i className="bi bi-toggles"></i> ML Configuration
                  </h5>
                  <p>
                    Early detection of server down is <b>disabled</b>. You can switch
                    on anomaly detection for this monitor by clicking below
                    button. You can disable it whenever you want.
                  </p>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      if (selectedId !== undefined) {
                        dispatch(toggleMonitorML(selectedId));
                      }
                    }}
                  >
                    Enable Anomaly Detection
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
