import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deletePage, fetchPages, getPages } from "../slices/pageSlice";
import { useAppDispatch } from "../store";

export default function StatusPagesView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pages = useSelector(getPages);

  useEffect(() => {
    dispatch(fetchPages());
  }, [dispatch]);

  const pagesComponent = pages.map((page) => {
    return (
      <div key={page.id}>
        <b>{page.title}</b> at{" "}
        <a
          href={`${window.location.origin}/public/${page.slug}`}
          target="_blank"
          rel="noreferrer"
        >{`${window.location.origin}/public/${page.slug}`}</a>
        <button
          className="btn btn-sm  "
          onClick={(e) => {
            navigate(`/app/add-status-page`, {
              state: {
                page: page,
              },
            });
          }}
          title="Edit alert"
        >
          <i className="bi bi-pencil"></i> Edit
        </button>
        <button
          className="btn btn-sm"
          onClick={(e) => {
            dispatch(deletePage(page.id));
          }}
          title="Delete alert"
        >
          <i className="bi bi-trash"></i> Delete
        </button>
      </div>
    );
  });

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2 style={{ float: "left" }}>
              <i className="bi bi-display"></i> Status Pages
            </h2>

            <button
              className="btn btn-success "
              style={{ float: "right" }}
              onClick={(e) => {
                navigate(`/app/add-status-page`);
              }}
              title="Add new alert"
            >
              <i className="bi bi-plus"></i> Add status page
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-10 offset-md-1">
            <hr style={{ marginTop: "0px" }} />
            {pagesComponent.length === 0 && (
              <p>
                No status pages available. Please add your first status page by
                clicking "Add status page" button on the right.
              </p>
            )}
            {pagesComponent}
          </div>
        </div>
      </div>
    </div>
  );
}
