import { AlertDataType } from "../slices/pageSlice";

type Props = {
  alert: AlertDataType;
};

export default function PublicAlertComponent({ alert }: Props) {
  return (
    <div>
      <p>
        <big>
          {alert.notification === "alert" && (
            <i className="bi bi-exclamation-circle"></i>
          )}
          {alert.notification === "warning" && (
            <i className="bi bi-info-circle"></i>
          )}
          {alert.notification === "note" && <i className="bi bi-card-text"></i>}{" "}
          {alert.msg}
        </big>
        <br />
        <small>
          Monitor {alert.monitor_title} ({alert.monitor_endpoint}){" "}
          {alert.created_at.toLocaleString()}
        </small>{" "}
      </p>
    </div>
  );
}
