type Props = {
  totalRequestsCount: number;
  avgPercentCorrect: number;
  totalFailurePeriod: number;
  avgResponseTime: number;
  totalDays: number;
};

export default function MonitorNumbers({
  totalRequestsCount,
  avgPercentCorrect,
  totalFailurePeriod,
  avgResponseTime,
  totalDays,
}: Props) {
  const round2 = (n: number) => {
    return Math.round(n * 100) / 100;
  };

  return (
    <div>
      <div className="row py-2">
        <div className="col-md-3">
          <div className=" boxes">
            <b>REQUESTS COUNT</b>
            <br />
            {totalRequestsCount !== undefined &&
              totalRequestsCount !== null && (
                <h2>{totalRequestsCount.toLocaleString()}</h2>
              )}
            {(totalRequestsCount === undefined ||
              totalRequestsCount === null) && (
              <p>
                <b>No data</b>
              </p>
            )}
          </div>
        </div>

        <div className="col-md-3  ">
          <div className=" boxes">
            <b>AVG RESPONE TIME</b>
            {avgResponseTime !== undefined && (
              <h2>{round2(avgResponseTime)} ms </h2>
            )}
            {avgResponseTime === undefined && (
              <p>
                <b>No data</b>
              </p>
            )}
          </div>
        </div>

        <div className="col-md-3  ">
          <div className=" boxes">
            <b>UP TIME</b>
            {avgPercentCorrect !== undefined && (
              <h2>{round2(avgPercentCorrect)}%</h2>
            )}
            {avgPercentCorrect === undefined && (
              <p>
                <b>No data</b>
              </p>
            )}
          </div>
        </div>

        <div className="col-md-3  ">
          <div className=" boxes">
            <b>DOWN TIME</b>
            {totalFailurePeriod !== undefined && (
              <h2>{totalFailurePeriod} sec</h2>
            )}
            {totalFailurePeriod === undefined && (
              <p>
                <b>No data</b>
              </p>
            )}
          </div>
        </div>
        <small style={{ color: "dimgray", fontFamily: "Monospace" }}>
          {totalDays} day{totalDays > 1 && "s"} statistics
        </small>
      </div>
    </div>
  );
}
