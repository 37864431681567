import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState, TypedDispatch } from "../store";

export type StatusPageType = {
  id: number;
  slug: string;
  title: string;
  description: string;
  monitors: number[];
};

export type MonitorDataType = {
  title: string;
  endpoint: string;
  x_data: number[];
  y_percent_correct: number[];
  y_avg_response_time: number[];
};

export type AlertDataType = {
  monitor_title: string;
  monitor_endpoint: string;
  notification: string;
  msg: string;
  created_at: Date;
};

export type PublicStatusPageType = {
  title: string;
  description: string;
  monitors_data: MonitorDataType[];
  alerts_data: AlertDataType[];
};

const initialState = {
  pages: [] as StatusPageType[],
  publicPage: {} as PublicStatusPageType,
};

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPages(state, action: PayloadAction<StatusPageType[]>) {
      state.pages = action.payload;
    },
    setPublicPage(state, action: PayloadAction<PublicStatusPageType>) {
      state.publicPage = action.payload;
    },
  },
});

export default pageSlice.reducer;

export const { setPages, setPublicPage } = pageSlice.actions;

export const getPages = (state: RootState) => state.page.pages;
export const getPublicPage = (state: RootState) => state.page.publicPage;

export const fetchPages = () => async (dispatch: TypedDispatch) => {
  try {
    const url = `/api/pages/`;
    const response = await axios.get(url);
    dispatch(setPages(response.data));
  } catch (error) {
    toast.error("Cannot fetch status pages from server");
  }
};

export const addPage =
  (
    pageId: number | undefined, // not used, just to have the same function signature as updatePage
    slug: String,
    title: String,
    description: String,
    monitors: number[],
    navigate: NavigateFunction
  ) =>
  async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/pages/`;
      await axios.post(url, {
        slug,
        title,
        description,
        monitors,
      });
      toast.success("New status page added");
      navigate("/app/status-pages");
    } catch (error) {
      toast.error("Cannot add a new status page");
    }
  };

export const deletePage =
  (pageId: number) => async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/pages/${pageId}`;
      await axios.delete(url);
      toast.success("Status page deleted");
      dispatch(fetchPages());
    } catch (error) {
      toast.error("Cannot delete status page");
    }
  };

export const updatePage =
  (
    pageId: number | undefined,
    slug: String,
    title: String,
    description: String,
    monitors: number[],
    navigate: NavigateFunction
  ) =>
  async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/pages/${pageId}/`;
      await axios.patch(url, {
        slug,
        title,
        description,
        monitors,
      });
      toast.success("Status page updated");
      navigate("/app/status-pages");
    } catch (error) {
      toast.error("Cannot update status page");
    }
  };

export const fetchPublicPage =
  (slug: string) => async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/public/${slug}`;
      const response = await axios.get(url);
      dispatch(setPublicPage(response.data));
    } catch (error) {}
  };
