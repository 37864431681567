import { useNavigate } from "react-router-dom";

export default function HomeView() {
  let navigate = useNavigate();

  return (
    <div>
      <div className="container">
        <div className="row">
          <div
            className="col-md-5 offset-md-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <h1>Keep an eye on your servers</h1>
              <p style={{ fontSize: "1.2em", paddingTop: "10px" }}>
                Monitor servers and websites' uptime. Detect anomalies with
                Machine Learning. Receive email notifications.
              </p>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  navigate("/register");
                }}
              >
                Start for free!
              </button>
              <button
                type="button"
                className="btn btn-outline-success m-1"
                onClick={(e) => {
                  navigate("/about");
                }}
              >
                Learn more
              </button>
            </div>
          </div>
          <div className="col-md-5">
            <img
              alt="monitor uptime of servers"
              src={process.env.PUBLIC_URL + "/uptime-monitoring.png"}
              style={{ width: "80%" }}
            />
          </div>
        </div>
      </div>
      <div className="p-4"></div>
      <div className="p-4"></div>
      <div className="container-fluid" style={{ backgroundColor: "#efefef" }}>
        <div className="container py-5">
          <div className="row">
            <div className=" col-md-5 offset-md-1">
              <div className=" " style={{ alignItems: "center" }}>
                <img
                  alt="monitor server uptime"
                  src={process.env.PUBLIC_URL + "/icon-monitor-server.png"}
                  style={{
                    width: "30%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                />
              </div>
              <h2 style={{ textAlign: "center" }}>Monitor uptime</h2>
              <p className="px-3 pb-4">
                Monitor your server uptime in selected time intervals. Intervals
                can be 1 min, 2 min, 5 min, 10 min, 15 min, 30 min, 1 hour or 1
                day. You decide!
              </p>
            </div>
            <div className="col-md-5">
              <div>
                <img
                  alt="detect anomalies"
                  src={process.env.PUBLIC_URL + "/icon-detect-anomalies.png"}
                  style={{
                    width: "30%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                />
              </div>
              <h2 style={{ textAlign: "center" }}>Detect anomalies</h2>
              <p className="px-3 pb-4">
                Detect problem before it happen. We use{" "}
                <a
                  href="https://scikit-learn.org/stable/modules/generated/sklearn.neighbors.LocalOutlierFactor.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  LocalOutlierFactor
                </a>{" "}
                (Machine Learning) algorithm for detecting anomalies in the
                reponse time.
              </p>
            </div>
            <div className="feature col-md-5 offset-md-1">
              <div className="feature-icon">
                <img
                  alt="email notifications"
                  src={process.env.PUBLIC_URL + "/icon-email-notification.png"}
                  style={{
                    width: "30%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                />
              </div>
              <h2 style={{ textAlign: "center" }}>Email notifications</h2>
              <p className="px-3 pb-4">
                Receive email notification when server is down or when anomaly
                is detected.
              </p>
            </div>
            <div className="feature col-md-5">
              <div className="feature-icon">
                <img
                  alt="status pages"
                  src={process.env.PUBLIC_URL + "/icon-public-status-page.png"}
                  style={{
                    width: "30%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                />
              </div>
              <h2 style={{ textAlign: "center" }}>Public status page</h2>
              <p className="px-3 pb-4">
                Show your uptime status for your users. Create public status
                page with your branding.
              </p>
            </div>

            <div className="p-2"></div>
          </div>
        </div>
      </div>

      <div className="p-4"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-5 offset-md-1">
            <img
              alt="learn how to build"
              src={process.env.PUBLIC_URL + "/croco.jpg"}
              style={{ width: "80%" }}
            />
          </div>

          <div
            className="col-md-5"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <h2>Learn how to build this service!</h2>
              <p style={{ fontSize: "1.2em", paddingTop: "10px" }}>
                This service was created as a tutorial on how to build SaaS
                product from scratch. The service was created with TypeScript &
                React in the fronted, and Python & Django in the backend. This
                service is fully functional. You can register new user, buy a
                subscription, setup server monitoring.
              </p>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) =>
                  window.open("https://saasitive.com/pricing/", "_blank")
                }
              >
                Get the course!
              </button>
              <button
                type="button"
                className="btn btn-outline-success m-1"
                onClick={(e) =>
                  window.open(
                    "https://saasitive.com/react-django-tutorial/",
                    "_blank"
                  )
                }
              >
                Learn more
              </button>
            </div>
          </div>
        </div>
        <div className="p-4"></div>
      </div>
    </div>
  );
}

